import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useModal } from "../hooks";
import Button from "./SimpleButton";
import ReactGA from "react-ga";
import LogRocket from "logrocket";

const Banner = styled.div`
    z-index: 9999;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #3d7ffa;
    padding: 0.5rem;
    color: #333;

    button {
        margin-left: 0.5rem;

        &.accept {
            background-color: #333;
            color: white;
        }
    }
`;

const Inputs = styled.div`
    /* margin-top: 1rem; */
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
        margin-top: 1rem;
        font-size: 1.2rem;
    }
`;

const CookieBanner = () => {
    const config = useSelector(state => state.config);
    const [showBanner, setShowBanner] = useState(
        localStorage.getItem("cookies") === null
    );
    const [nonNecessary, setNonNecessary] = useState(true);
    const { Modal, show, hide } = useModal();

    if (!showBanner) return null;

    const handleAccept = () => {
        hide();
        localStorage.setItem("cookies", nonNecessary);

        // Begin tracking if allowed
        if (nonNecessary) {
            // Google Analytics
            if (config.googleAnalyticsTrackingID)
                ReactGA.initialize(config.googleAnalyticsTrackingID);

            // LogRocket
            LogRocket.init("zev0ns/mathsadvance");
        }

        setShowBanner(false);
    };

    return (
        <Banner>
            <Modal>
                <h2>Accept the use of cookies</h2>
                <p>
                    This website uses cookies to improve your experience. The
                    cookies are categorized as necessary are stored on your
                    browser as they are essential for the working of basic
                    functionalities of the website. We also use third-party
                    cookies that help us analyze and understand how you use this
                    website. These cookies will be stored in your browser only
                    with your consent. But opting out of some of these cookies
                    may have an effect on your browsing experience.
                </p>
                <Inputs>
                    <div>
                        <label>Necessary cookies</label>
                        <input
                            style={{ marginLeft: "1rem" }}
                            type="checkbox"
                            checked
                            disabled
                        />
                    </div>
                    <div>
                        <label>Non-Necessary cookies</label>
                        <input
                            style={{ marginLeft: "1rem" }}
                            type="checkbox"
                            checked={nonNecessary}
                            onChange={() => setNonNecessary(!nonNecessary)}
                        />
                    </div>
                </Inputs>
            </Modal>
            We use cookies to improve your experience on our site.{" "}
            <Button onClick={show}>More options</Button>{" "}
            <Button onClick={handleAccept} className="accept">
                Accept
            </Button>
        </Banner>
    );
};

export default CookieBanner;
