import { useQuery } from "@apollo/client"
import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import Loading from "../components/Loading"
import { GET_STRIPE_PORTAL_URL } from "../queries"
import { setNotification } from "../reducers/notificationReducer"

const ManageSubscription = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    useQuery(GET_STRIPE_PORTAL_URL, {
        onCompleted: ({ getStripePortalURL }) => {
            window.location = getStripePortalURL
            history.replace("/")
            dispatch(setNotification("Redirecting to Stripe, please wait...", "SUCCESS"))
        },
        onError: e => {
            dispatch(setNotification(e.message, "ERROR"))
            history.replace("/")
        }
    })
    
    return <Loading />
}

export default ManageSubscription