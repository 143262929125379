import React from "react"

import styled from "styled-components/macro"

const BottomText = styled.div`
    font-weight: bold;
    font-size: 1.25rem;
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    text-align: center;
    user-select: none;
`

const Title = styled.h1`
    font-size: 2rem;
    @media only screen and (min-width: 990px) {
        font-size: 2.5rem;
    }
`

const StyledSection = styled.section`
    max-width: 80rem;
    width: 80%;
    margin: 0 auto;

    text-align: center;
    animation: fadeIn 250ms;

    /* Add margin if no title */
    ${({ sectionTitle }) => !sectionTitle && "margin-top: 3rem;"}

    /* Display background image if specified in props */
    ${({ backgroundImage }) => backgroundImage && `
        background: url(${backgroundImage}) no-repeat;
        background-position: center;
        background-size: auto 100%;
    `}

    /* Hero section styling */
    ${({ hero }) => hero && `
        min-height: 100vh;
        max-width: initial;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `}

    /* Bottom text animation */
    ${({ bottomTextBounce }) => bottomTextBounce && `
        ${BottomText} {
            animation: bounce 5s ease infinite;
        }
    `}
    
    /* Optional padding */
    ${({ padded }) => padded && `
        padding: 0 2rem;

        @media only screen and (min-width: 990px) {
            padding: 0;
        }
    `}

    ${Title} {
        margin: 40px auto 1rem;
    }

    @media only screen and (min-width: 990px) {
        padding: 0;
        text-align: justify;

        /* Remove margin on desktop if no title */
    ${({ sectionTitle }) => !sectionTitle && "margin-top: 2rem;"}
        
        &:after {
            font-size: 1.1rem;
        }

        ${Title} {
            text-align: center;
        }
    }
`

const Section = ({ className, title, backgroundImage = null, hero = backgroundImage !== null && backgroundImage !== "", bottomText = false, bottomTextBounce = false, bottomTextOnClick, largeTitle = false, padded = false, children }) => {
    return <StyledSection sectionTitle={title} backgroundImage={backgroundImage} hero={hero} bottomText={bottomText} bottomTextBounce={bottomTextBounce} padded={padded}>
            <div className={className}>
                {title && <Title largeTitle={largeTitle}>{title}</Title>}
                {children}
            </div>
        {bottomText && <BottomText>
                <span style={bottomTextOnClick? {cursor:"pointer"} : {}} onClick={bottomTextOnClick}>{bottomText}</span>
            </BottomText>}
    </StyledSection>
}

export default Section