import React, { useState } from "react"
import ModalComponent from "./components/ModalComponent"

export const useModal = (initVisible = false, onClose) => {
    const [visible, setVisible] = useState(initVisible)

    const show = () => setVisible(true)
    const hide = () => {
        if(onClose) onClose()
        setVisible(false)
    }
    const toggle = () => setVisible(!visible)

    const Modal = ({ style, children }) =>
        visible
            ? <ModalComponent style={style} closeModal={hide}>{children}</ModalComponent>
            : null

    return { show, hide, toggle, Modal }
}