import axios from "axios"
import store from "../store"
import { loadUser, clearUser } from "../reducers/userReducer"
import { print } from "graphql"
import { ADD_MEMBER, LOGIN, LOGOUT, GET_CONFIG, EXPORT_USERS } from "../queries"
import { loadConfig } from "../reducers/configReducer"
import { googleLogout } from '@react-oauth/google';
import { setNotification } from "../reducers/notificationReducer"
axios.defaults.withCredentials = true

const baseUrl = process.env.REACT_APP_GRAPHQL_URI

export const register = async (params) => {
    const { firstName, lastName, email, password, schoolYear = "Not set", type, notes = "" } = params
    const req = await axios.post(baseUrl, {
        query: print(ADD_MEMBER),
        variables: {
            user: {
                firstName,
                lastName,
                email,
                password,
                schoolYear,
                type,
                notes,
            }
        }
    })
    if (req.data.errors) {
        // Validation errors are in req.data.errors[0].extensions.exception.errors
        // all other errors are in req.data.errors[0].message
        if (!req.data.errors[0].extensions.exception.errors) throw new Error(req.data.errors[0].message)
        const errors = req.data.errors[0].extensions.exception.errors
        for (let error in errors) {
            throw new Error(errors[error].properties.message)
        }
    }
    const user = req.data.data.addMember
    return user
}

export const login = async (email, password) => {
    const req = await axios.post(baseUrl, {
        query: print(LOGIN),
        variables: { email, password }
    })
    if (req.data.errors) throw new Error(req.data.errors[0].message)

    // Get config
    const confReq = await axios.post(baseUrl, { query: print(GET_CONFIG) })
    if (confReq.data.errors) throw new Error(confReq.data.errors[0].message)
    else store.dispatch(loadConfig(confReq.data.data.getConfig))

    const user = req.data.data.login
    store.dispatch(loadUser(user))
    return user
}

export const onLoginSuccess = async (user) => {
    const confReq = await axios.post(baseUrl, { query: print(GET_CONFIG) })
    if (confReq.data.errors) throw new Error(confReq.data.errors[0].message)
    else {
        store.dispatch(loadConfig(confReq.data.data.getConfig));
        store.dispatch(setNotification(`Welcome back ${user?.firstName}!`, "SUCCESS"));
        store.dispatch(loadUser(user))
    }
}

export const exportUsers = async () => {
    const req = await axios.post(baseUrl, { query: print(EXPORT_USERS) })
    if (req.data.errors) throw new Error(req.data.errors[0].message)
    return req.data.data.exportUsers
}

export const logout = async () => {
    try {
        googleLogout()
        await axios.post(baseUrl, {
            query: print(LOGOUT)
        })
        store.dispatch(clearUser())
    } catch (error) {
        console.error(error)
    }

}