import styled from "styled-components/macro"

const SimpleButton = styled.button`
    padding: .5rem .75rem;
    background: none;
    border: 2px solid #333;
    cursor: pointer;
    transition: all 250ms ease;

    &:disabled {
        border-color: #999;
        color: #999;
        cursor: default;
    }

    &:hover:not(:disabled) {
        background: #333;
        color: white;
        transform: scale(1.1);
    }
`

export default SimpleButton