import { useApolloClient, useMutation } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useModal } from "../../../hooks";
import { EDIT_PASSWORD } from "../../../queries";
import { setNotification } from "../../../reducers/notificationReducer";
import { logout } from "../../../services/users";
import logo from "../../../static/New logo.svg";
import user_icon from "../../../static/user-icon.svg";
import { Form, Input } from "../../Form";


const Header = () => {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const client = useApolloClient();

  const [show, setShow] = useState(false);

  // Change password modal
  const { Modal, show: showModal, hide: hideModal } = useModal();

  const [changePassword] = useMutation(EDIT_PASSWORD, {
    onError: (e) => dispatch(setNotification(e.message, "ERROR")),
    onCompleted: () => {
      dispatch(setNotification("Password changed successfully", "SUCCESS"));
      hideModal();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentPassword = e.target.currentPassword.value;
    const newPassword = e.target.newPassword.value;
    const confirmNewPassword = e.target.confirmNewPassword.value;
    if (newPassword !== confirmNewPassword)
      dispatch(
        setNotification(
          "New password and confirm new password do not match.",
          "ERROR"
        )
      );
    else
      changePassword({
        variables: {
          currentPassword,
          newPassword,
        },
      });
  };

  // const handleLogout = async () => {
  //   setShow(false);
  //   await client.resetStore();
  //   await logout();
  // };

  // Close mobile nav is windows is resized (>990px matching media query above)
  window.addEventListener(
    "resize",
    () => {
      if (window.innerWidth > 990 && show) setShow(false);
    },
    false
  );
  const menuRef = useRef();
  const overlayRef = useRef();
  const { instance } = useMsal();
  const [showNav, setShowNav] = useState(false);

  const handleMenuOpen = () => {
    overlayRef.current.classList.add("bg-gray-500");
    menuRef.current.classList.remove("animate-fade-out");
    menuRef.current.classList.add("animate-fade-in");
    setShowNav(true);
  };
  const handleMenuClose = () => {
    overlayRef.current.classList.remove("bg-gray-500");
    menuRef.current.classList.remove("animate-fade-in");
    menuRef.current.classList.add("animate-fade-out");
    setTimeout(() => {
      setShowNav(false);
    }, "200");
  };
  const handleMobileLogin = () => {
    overlayRef.current.classList.remove("bg-gray-500");
    menuRef.current.classList.remove("animate-fade-in");
    menuRef.current.classList.add("animate-fade-out");
    setTimeout(() => {
      setShowNav(false);
    }, "200");
    setShow(false);
  };

  //show and hide dropdown
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };
  const handleLogout = async () => {

    setShow(false)
    await client.resetStore()
    await logout()
    await instance.clearCache();
    //if (instance.getActiveAccount()) await instance.logoutPopup({ postLogoutRedirectUri: "/" });
    window.location.href = "/"
  }
  // Shrunk header state
  const [isShrunk, setShrunk] = useState(false)
  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false
        }

        return isShrunk
      })
    }

    window.addEventListener('scroll', handler)
    return () => window.removeEventListener('scroll', handler)
  }, [])
  return (
    <header>
      <div style={{ boxShadow: isShrunk ? "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px" : "none" }} className="container min-w-full bg-white z-[100] h-[135px] md:h-[142px] flex items-center transition-all fixed">
        <Link
          to="/"
          className='after:text-[#4463F2] after:text-[35px]
            after:font-bold after:absolute after:mt-[-40px] after:ml-[32px]'
        >
          <img src={logo} width={200} alt="homelogo" />
        </Link>
        <div className="flex-1"></div>

        {/* MOBILE NAV BTN */}
        <div className=" md:hidden right-[16px]">
          <button
            className="p-[12px] bg-white rounded-lg transition-all duration-300 hover:bg-[#4463F2]/30"
            aria-label="Mobile Navigation Button"
            onClick={handleMenuOpen}
          >
            <img src="/img/nav.svg" alt="nav" />
          </button>
        </div>
        <Link
          to="/questions"
          className="px-[50px] py-[11px] rounded-[40px] text-[16px]
            bg-[#4463F2] text-white hover:bg-[#4463F2]/70 transition-all duration-300 absolute left-1/2 -translate-x-1/2 hidden lg:block"
        >
          Questions
        </Link>
        {/* PC NAV */}
        <nav
          id="desktop-navigation"
          role="navigation"
          aria-label="Desktop Navigation"
          className=" hidden md:flex"
        >
          <a
            href="https://mathsadvance.blog/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex  mx-[20px] items-center   text-[#4463F2] hover:text-[#4463F2]/70 text-[16px]
            transition-all duration-300"
          >
            Blog
          </a>
          <Link
            to="/about"
            className="flex  mx-[20px] items-center   text-[#4463F2] hover:text-[#4463F2]/70 text-[16px]
            transition-all duration-300"
          >
            About
          </Link>
          <div className="flex flex-col justify-center">
            {!user && (
              <Link
                to="/login"
                className="flex items-center mx-[20px] ] text-[#4463F2] hover:text-[#4463F2]/70  text-[16px]
             transition-all duration-300"
                onClick={() => setShow(false)}
              >
                Login / Subscribe
              </Link>
            )}
            {user && (
              <div
                className="dropdown relative flex items-center p-4"
                ref={dropdownRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={user_icon} className="h-8 w-auto" alt="" />
                <div
                  className={`dropdown-wrapper absolute top-[2.5rem] right-0 pt-[1rem] group-hover:flex ${showDropdown ? "flex" : "hidden"
                    }`}
                >
                  <div
                    className={`dropdown-content flex flex-col justify-items-center bg-white p-[1rem] border-2 border-solid border-black w-max text-[1rem] `}
                  >
                    <p>
                      Hi {user?.firstName}!{" "}
                      <span className="p-[.5rem] font-bold text-[.75rem] bg-[#4463F2]">
                        {user?.type?.replace("_", " ")}
                      </span>
                    </p>
                    {["MONTHLY_MEMBER", "YEARLY_MEMBER"].includes(
                      user?.type
                    ) && (
                        <Link
                          className="p-[1rem] border-2 mt-3 mb-2 border-solid border-black hover:bg-[#4463F2] hover:text-white"
                          to="/manage-subscription"
                        >
                          Manage subscription
                        </Link>
                      )}
                    <Link
                      className="p-[1rem] border-2 mt-3 mb-2 border-solid border-black hover:bg-[#4463F2] hover:text-white"
                      to="#"
                      onClick={() => showModal()}
                    >
                      Change Password
                    </Link>

                    <Modal>
                      <h1 className="text-[2rem] lg:text-[2.5rem]">
                        Change password
                      </h1>
                      <Form className="compact" onSubmit={handleSubmit}>
                        <Input
                          type="password"
                          label="Current Password"
                          name="currentPassword"
                          required
                        />
                        <Input
                          type="password"
                          label="New Password"
                          name="newPassword"
                          required
                        />
                        <Input
                          type="password"
                          label="Confirm New Password"
                          name="confirmNewPassword"
                          required
                        />
                        <button className="mt-[2rem] p-[1rem] shadow-2xl transition-transform duration-500 ease-in-out hover:scale-110 cursor-pointer bg-[#4463F2]">
                          Change password
                        </button>
                      </Form>
                    </Modal>

                    <button
                      className="p-[1rem] text-left border-2 my-2 border-solid border-black hover:bg-[#4463F2] hover:text-white"
                      //to="/"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                    {user && user?.type === "ADMIN" && (
                      <Link
                        className="p-[1rem] border-2 my-2 border-solid border-black hover:bg-[#4463F2] hover:text-white"
                        to="/admin"
                        onClick={() => setShow(false)}
                      >
                        Admin
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </nav>
      </div>

      {/* MOBILE */}
      <nav
        className={` top-0 
       z-[100] min-h-screen w-screen  left-0 ${showNav ? "fixed" : "hidden"}`}
      >
        <div
          ref={overlayRef}
          className="bg-opacity-30 min-h-full w-full absolute "
          onClick={handleMenuClose}
        ></div>
        <div
          ref={menuRef}
          onClick={(e) => e.stopPropagation()}
          className="right-0 z-50 min-h-full w-full  bg-[#4463F2] absolute"
        >
          <div className="container bg-[#4463F2] min-w-full fixed md:relative z-[101] py-[32px]  md:py-[40px] flex items-center">
            <Link
              to="/"
              className=' after:content-["MA"] lg:after:content-["MATHS_ADVANCE"]  after:text-[#fff] after:text-[35px]
            after:font-bold after:absolute after:mt-[-40px] after:ml-[32px]'
            >
              <img src="/img/logoWhite.svg" alt="homelogo" />
            </Link>
            <div className="flex-1"></div>
            <button
              aria-label="Mobile Navigation Button"
              className="right-[1rem] sm:right-[2rem] w-[62px] h-[42px] flex justify-center items-center top-[35px]"
              onClick={handleMenuClose}
            >
              <img src="/img/close.svg" alt="close" />
            </button>
          </div>
          <nav
            id="mobile-navigation"
            role="navigation"
            aria-label="Mobile Navigation"
            className="flex h-[100vh] justify-center items-center flex-col mt-[16px] z-[100] relative"
          >
            {!user && <Link
              onClick={handleMobileLogin}
              to="/login"
              className="text-white  hover:bg-white justify-center transition-all duration-300 hover:text-[#4463F2] flex items-center  text-[30px]  h-[64px] w-full "
            >
              <span>Login</span>
              <div></div>
            </Link>}
            <Link
              onClick={handleMenuClose}
              to="/subscribe"
              className={`${user && "mt-5"} text-white hover:bg-white justify-center transition-all duration-300 hover:text-[#4463F2] flex items-center  text-[30px]  h-[64px] w-full`}
            >
              <span>Subscribe</span>
              <div></div>
            </Link>
            <Link
              onClick={handleMenuClose}
              to="/questions"
              className="text-white  hover:bg-white justify-center transition-all duration-300 hover:text-[#4463F2]  flex items-center  text-[30px]  h-[64px] w-full "
            >
              <span>Questions</span>
            </Link>
            <Link
              onClick={handleMenuClose}
              to="/about"
              className="text-white  hover:bg-white justify-center transition-all duration-300 hover:text-[#4463F2] flex items-center  text-[30px]  h-[64px] w-full "
            >
              <span>About</span>
            </Link>
            {user && (
              <div className="dropdown-content flex flex-col justify-items-center p-[1rem] w-max text-[1rem]">
                {["MONTHLY_MEMBER", "YEARLY_MEMBER"].includes(user.type) && (
                  <Link
                    className="p-[1rem] my-3 border-2 border-solid border-black text-white  hover:bg-white justify-center transition-all duration-300 hover:text-[#4463F2]  flex items-center  text-[30px]  h-[64px] w-full"
                    to="/manage-subscription"
                  >
                    Manage subscription
                  </Link>
                )}
                <Link
                  className="p-[1rem] text-white  hover:bg-white justify-center transition-all duration-300 hover:text-[#4463F2]  flex items-center  text-[30px]  h-[64px] w-full my-3 border-2 border-solid border-black"
                  to="#"
                  onClick={() => showModal()}
                >
                  Change Password
                </Link>

                <Modal>
                  <h1 className="text-[2rem] lg:text-[2.5rem]">
                    Change password
                  </h1>
                  <Form className="compact" onSubmit={handleSubmit}>
                    <Input
                      type="password"
                      label="Current Password"
                      name="currentPassword"
                      required
                    />
                    <Input
                      type="password"
                      label="New Password"
                      name="newPassword"
                      required
                    />
                    <Input
                      type="password"
                      label="Confirm New Password"
                      name="confirmNewPassword"
                      required
                    />
                    <button className="mt-[2rem] p-[1rem] shadow-2xl transition-transform duration-500 ease-in-out hover:scale-110 cursor-pointer bg-[#4463F2]">
                      Change password
                    </button>
                  </Form>
                </Modal>

                <button
                  className="p-[1rem] text-white  hover:bg-white justify-center transition-all duration-300 hover:text-[#4463F2]  flex items-center  text-[30px]  h-[64px] w-full my-3  border-2 border-solid border-black"
                  onClick={handleLogout}
                >
                  Logout
                </button>
                {user && user.type === "ADMIN" && (
                  <Link
                    className="p-[1rem] text-white  hover:bg-white justify-center transition-all duration-300 hover:text-[#4463F2]  flex items-center  text-[30px]  h-[64px] w-full my-3 border-2  border-solid border-black"
                    to="/admin"
                    onClick={() => setShow(false)}
                  >
                    Admin
                  </Link>
                )}
              </div>
            )}
          </nav>
        </div>
      </nav>
    </header>
  );
};

export default Header;
