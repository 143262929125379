const initialState = {
    font: "Comfortaa",
    primaryColor: "#4463f2"
}

const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOAD":
            return action.data

        case "ADD":
            return {...state, ...action.data}
    
        default:
            return state;
    }
}

export const loadConfig = data => {
    return dispatch => {
        dispatch({
            type: "LOAD",
            data
        })
    }
}

export default configReducer