import clsx from "clsx";
import React, { Suspense, lazy, useEffect } from "react";
import 'react-creative-cursor/dist/styles.css';
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import Button from "./components/Button";
import CookieBanner from "./components/CookieBanner";
import ErrorBoundary from "./components/ErrorBoundary";
import Loading from "./components/Loading";
import Footer from "./components/New-UI/Footer/Footer";
import Header from './components/New-UI/Header/Header';
import ScrollToTop from "./components/New-UI/scrollToTop";
import Notifications from "./components/Notifications";
import Section from "./components/Section";
import ManageSubscription from "./pages/ManageSubscription";
import favicon from './static/favicon.png';

const Questions = lazy(() => import("./pages/Questions"))
const Contact = lazy(() => import("./pages/Contact"))
// const Login = lazy(() => import("./pages/Login"))
const Admin = lazy(() => import("./pages/Admin"))
const PageFromAPI = lazy(() => import("./pages/PageFromAPI"))
const ResetPassword = lazy(() => import("./pages/ResetPassword"))

// New Page Template
const Home = lazy(() => import("./pages/New-UI/Homepage"))
const About = lazy(() => import("./pages/New-UI/About"))
const Login = lazy(() => import("./pages/New-UI/Login"))
const QuestionsNew = lazy(() => import("./pages/New-UI/Question"))
const Subcribe = lazy(() => import("./pages/New-UI/Subcribe"))
const Success = lazy(() => import("./pages/New-UI/Success"))

const defaultPageInfo = {
    title: "Maths Advance - Extend and Enrich. For Mastery.",
    description: "Let us prepare pupils to answer questions on the most challenging level 8/9 content. Our questions bring together different topic areas and require a deeper level of understanding than on other GCSE revision sources.",
    url: "https://mathsadvance.co.uk/",
    og_image: "https://mathsadvance.co.uk/img/og.png"
}

const Content = styled.main`
    &.center {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .banner {
        display: block;
        padding: 2rem 3rem;
        background: ${({ theme }) => theme.primary ? theme.primary : "#4463f2"};
        box-shadow: 0 6px 6px -6px #777;
        text-align: center;
    }

    .snapScrolling {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const App = () => {
    const location = useLocation()
    const user = useSelector(state => state.user)

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])

    return <>
        <Helmet>
            <title>{defaultPageInfo.title}</title>
            <meta name="description" content={defaultPageInfo.description} />
            <meta property="og:title" content={defaultPageInfo.title} />
            <meta property="og:description" content={defaultPageInfo.description} />
            <meta property="og:image" content={defaultPageInfo.og_image} />
            <meta property="og:url" content={defaultPageInfo.url} />
            <meta name="twitter:card" content="summary_large_image" />
            <link rel="icon" type="image/png" href={favicon} />
        </Helmet>
        {/* <div className="hidden xl:block">
                <Cursor isGelly={true} animationDuration={0.75} cursorSize={20} cursorBackgrounColor={'#162F72'}/>
            </div> */}
        <Header />
        <Notifications />
        {navigator.userAgent !== "ReactSnap" && <CookieBanner />}

        {/* Center content in the page except for "admin", "subscribe" and "questions" pages */}
        <Content id="pageContent" className={clsx(location.pathname === '/' && "home", location.pathname === '/subscribe' && "pg-container pt-[135px] md:pt-[142px]", "center")} >
            <Suspense fallback={<Loading />}>
                <ErrorBoundary>
                    <Switch>
                        <Route path="/reset-password/:token">
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <ResetPassword />}
                        </Route>
                        <Route path="/success/:id">
                            {navigator.userAgent === "ReactSnap" ? <Loading /> : <Success />}
                        </Route>
                        <Route path="/questions/:branch?">
                            <ScrollToTop />
                            <Helmet>
                                <title>Maths Advance - Questions</title>
                                <meta name="description" content="Practice challenging questions from our team! Video solutions will explain how to answer questions in the most effective way. Check the free questions to get started if you're not yet a member." />
                                <meta property="og:title" content="Maths Advance - Questions" />
                                <meta property="og:description" content="Practice challenging questions from our team! Video solutions will explain how to answer questions in the most effective way. Check the free questions to get started if you're not yet a member." />
                                <meta property="og:url" content="https://mathsadvance.co.uk/questions" />
                            </Helmet>
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <Questions />}
                        </Route>
                        <Route path="/home-revamp">
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <PageFromAPI name="HomeRevamp" />}
                        </Route>
                        <Route path="/cancel">
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : user
                                    ? <Section largeTitle title="The payment has been cancelled">
                                        <div style={{ textAlign: "center" }}>
                                            <p style={{ marginBottom: "1rem" }}>Unfortunately we couldn't process the payment to upgrade your account. Rest assured you have not been charged.</p>
                                            <Link to="/subscribe"><Button>Choose a subscription plan</Button></Link>
                                        </div>
                                    </Section>
                                    : <Section largeTitle title="Restricted page">
                                        <p style={{ textAlign: "center" }}>Please login to your account to check your membership status.</p>
                                    </Section>}
                        </Route>

                        

                        <Route path="/manage-subscription">
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <ManageSubscription />}
                        </Route>



                        <Route path="/terms-and-conditions">
                            <Helmet>
                                <title>Maths Advance - Terms and Conditions</title>
                                <meta name="description" content="This page sets out your rights and responsabilities when using our services." />
                                <meta property="og:title" content="Privacy Policy" />
                                <meta property="og:description" content="This page sets out your rights and responsabilities when using our services." />
                                <meta property="og:url" content="https://mathsadvance.co.uk/terms-and-conditions" />
                            </Helmet>
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <PageFromAPI name="Terms and Conditions" />}
                        </Route>

                        <Route path="/privacy-policy">
                            <Helmet>
                                <title>Maths Advance - Privacy Policy</title>
                                <meta name="description" content="This page details what information we store about you, how we process this data and how you can contact us for more details." />
                                <meta property="og:title" content="Privacy Policy" />
                                <meta property="og:description" content="This page details what information we store about you, how we process this data and how you can contact us for more details." />
                                <meta property="og:url" content="https://mathsadvance.co.uk/privacy-policy" />
                            </Helmet>
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <PageFromAPI name="Privacy Policy" />}
                        </Route>

                        <Route path="/admin">
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <Admin />}
                        </Route>

                        <Route path="/subscribe">
                            <Helmet>
                                <title>Maths Advance - Subscribe</title>
                                <meta name="description" content="Interested in our service? Choose a plan that suits your need and start practising today with challenging questions from our team! Video solutions will explain how to answer questions in the most effective way." />
                                <meta property="og:title" content="Maths Advance - Subscribe" />
                                <meta property="og:description" content="Interested in our service? Choose a plan that suits your need and start practising today with challenging questions from our team! Video solutions will explain how to answer questions in the most effective way." />
                                <meta property="og:url" content="https://mathsadvance.co.uk/subscribe" />
                            </Helmet>
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <Subcribe />}
                        </Route>

                        <Route path="/login">
                            <Helmet>
                                <title>Maths Advance - Login</title>
                                <meta property="og:title" content="Maths Advance - Login" />
                                <meta property="og:url" content="https://mathsadvance.co.uk/login" />
                            </Helmet>
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <Login />}
                        </Route>

                        

                        <Route path="/about">
                            <Helmet>
                                <title>Maths Advance - About</title>
                                <meta name="description" content="Many people choose to purchase private tuition to help their child realise their potential at GCSE and/or A-Level. With the average national rate for a GCSE Maths tutor being just under £25 per hour, however, this can accumulate to a significant sum of the weeks and months." />
                                <meta property="og:title" content="Maths Advance - About" />
                                <meta property="og:description" content="Many people choose to purchase private tuition to help their child realise their potential at GCSE and/or A-Level. With the average national rate for a GCSE Maths tutor being just under £25 per hour, however, this can accumulate to a significant sum of the weeks and months." />
                                <meta property="og:url" content="https://mathsadvance.co.uk/about" />
                            </Helmet>
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <About />}
                        </Route>

                        <Route path="/contact">
                            <Helmet>
                                <title>Maths Advance - Contact</title>
                                <meta name="description" content="Have any questions regarding our service? Not sure if this is for you? Or want to enquire about school pricing? Let us help you in answering your questions, and help you in boosting your maths grade!" />
                                <meta property="og:title" content="Maths Advance - Contact" />
                                <meta property="og:description" content="Have any questions regarding our service? Not sure if this is for you? Or want to enquire about school pricing? Let us help you in answering your questions, and help you in boosting your maths grade!" />
                                <meta property="og:url" content="https://mathsadvance.co.uk/contact" />
                            </Helmet>
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <Contact />}
                        </Route>

                        <Route exact path="/">
                            {navigator.userAgent === "ReactSnap"
                                ? <Loading />
                                : <PageFromAPI name="HomeRevamp" />}
                        </Route>

                        {/* 404 page */}
                        <Route path="*">
                            <Section largeTitle title="Page not found">
                                <p style={{ textAlign: "center" }}>It appears you are lost, please return to the <Link to="/">homepage</Link>. Otherwise do <Link to="/contact">contact us</Link>.</p>
                            </Section>
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </Suspense>
        </Content>
        <Footer />
    </>
}

export default App