import React from "react"
import styled, { keyframes } from "styled-components/macro"

// Spinner from loading.io
const ldsRingKeyframe = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    /* Spinner from loading.io */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #3D7FFA;
        border-radius: 50%;
        animation: ${ldsRingKeyframe} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #3D7FFA transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
`

Wrapper.defaultProps = {
    theme: {
        primary: "#4463f2"
    }
}

const Loading = () => {
    return <Wrapper>
        {/* Spinner from loading.io */}
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </Wrapper>
}

export default Loading