import React, { Component } from "react"
import LogRocket from "logrocket"
import { Link } from "react-router-dom"
import Section from "./Section"

export default class ErrorBoundary extends Component {
    state = { hasError: false }

    static getDerivedStateFromError(_) {
        return { hasError: true }
    }

    componentDidCatch(error, _) {   
        LogRocket.error(error)
    }

    render() {
        if (this.state.hasError) {
          // You can render any custom fallback UI
          return <Section largeTitle title="Something went wrong.">
              <p style={{ textAlign: "center" }}>Try reloading the page, otherwise do <Link to="/contact" onClick={() => this.setState({ hasError: false })}>contact us</Link>.</p>
          </Section>
        }
    
        return this.props.children 
    }
}