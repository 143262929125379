import React from "react"
import styled from "styled-components/macro"
import { createPortal } from "react-dom";

const Close = styled.span`
float: right;
font-size: 2.5rem;
line-height: 2.5rem;
cursor: pointer;
`

const Content = styled.div`
    border: solid 2px #333;
    border-radius: 1rem;
    background: white;
    width: 90%;
    max-height: 90%;
    padding: 1rem;
    position: relative;
    text-align: center;
    scrollbar-gutter: stable;
    overflow-y: auto; /* Display scrollbar if content doesn't fit modal */

    ::-webkit-scrollbar {
        width: 12px;
    }
    
    ::-webkit-scrollbar-track {
        margin: 1rem;
        border-radius: 1rem;
        background-color: #f1f1f1;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background-color: #c1c1c1;
    }

    /* Desktop */
    @media only screen and (min-width: 990px) {
        text-align: justify;
        padding: 3rem;
    }
`

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    z-index: 9999;
    animation: fadeIn 250ms;

    /* Desktop */
    @media only screen and (min-width: 990px) {
        ${Content} {
            width: 80%;
            max-width: 60rem;
        }
    }
`

const ModalComponent = ({ style, children, closeModal }) => {
    return createPortal(<ModalWrapper onMouseDown={closeModal}>
        <Content style={style} onMouseDown={e => e.stopPropagation()}>
            <Close onClick={closeModal}>×</Close>
            {children}
        </Content>
    </ModalWrapper>, document.getElementById("modal-root"))
}

export default ModalComponent