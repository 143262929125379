const notificationReducer = (state = [], action) => {
    switch(action.type) {
        case "ADD_NOTIFICATION":
            return state.concat(action.data)
        case "REMOVE_NOTIFICATION":
            return state.filter(n => n !== action.data)
        default:
            return state
    }
}

export const setNotification = (message, level = "NORMAL", time = 5) => {
    return dispatch => {
        const data = { message, level, id: Math.random() }
        dispatch({
            type: "ADD_NOTIFICATION",
            data
        })
        setTimeout(() => dispatch({
            type: "REMOVE_NOTIFICATION",
            data
        }), time*2000)
    }
}

export default notificationReducer