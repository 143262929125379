import { combineReducers, createStore, applyMiddleware } from "redux";
import userReducer from "./reducers/userReducer";
import thunk from "redux-thunk";
import notificationReducer from "./reducers/notificationReducer";
import configReducer from "./reducers/configReducer";
import LogRocket from "logrocket";

const reducer = combineReducers({
    user: userReducer,
    notification: notificationReducer,
    config: configReducer
})

const store = createStore(reducer, applyMiddleware(thunk, LogRocket.reduxMiddleware()))

export default store