import styled from "styled-components/macro"

const Button = styled.button`
    border: none;
    outline: none;
    text-decoration: none;
    background-color: ${({ theme }) => theme.primary};
    padding: 1rem;
    box-shadow: 0 6px 6px -6px #777;
    transition: transform 500ms ease;
    color: #fff;
    &:hover {
        transform: scale(1.1);
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }
`

Button.defaultProps = {
    theme: {
        primary: "#4463f2"
    }
}

export default Button