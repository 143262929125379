import React from "react"
import { createPortal } from "react-dom";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";

const mapLevelToColour = level => {
    switch (level) {
        case "ERROR":
            return "#c7ebe9"
        case "WARNING":
            return "#c7ebe9"
        case "SUCCESS":
            return "#c7ebe9"
        default:
            return "white"
    }
}

const NotificationContent = styled.div`
    max-width: 25rem;
    margin: 1rem auto 0;
    padding: 1rem;
    background: ${props => mapLevelToColour(props.level)};
    animation: fadeIn 500ms;
    color: #162f72;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
`

const NotificationWrapper = styled.div`
    position: fixed;
    left: 50%;
    bottom:3%;
    transform: translate(-50%, 0);
    z-index: 99999;
`

const Notifications = () => {
    const notifications = useSelector(state => state.notification)
    return createPortal(<NotificationWrapper>
        {notifications.map(n => <NotificationContent key={n.id} level={n.level}>{n.message}</NotificationContent>)}
    </NotificationWrapper>, document.getElementById("notification-root"))
}

export default Notifications