import LogRocket from "logrocket"

const userReducer = (state = null, action) => {
    switch(action.type) {
        case "LOAD_USER":
            return action.data
        case "CLEAR_USER":
            return null
        case "COMPLETED_QUESTION":
            const newState = {...state, completedQuestions: [...state.completedQuestions, action.data.questionID]}
            return newState
        case "TOGGLE_COMPLETED_QUESTION":
            const id = action.data.questionID
            let completedQuestions = [...state.completedQuestions]
            if(completedQuestions.includes(id)) completedQuestions = completedQuestions.filter(q => q !== id)
            else completedQuestions.push(id)
            return {...state, completedQuestions}
        default:
            return state
    }
}

export const loadUser = user => {
    return dispatch => {
        if(user) LogRocket.identify(user.id, user)
        dispatch({
            type: "LOAD_USER",
            data: user
        })
    }
}

export const clearUser = () => {
    return dispatch => {
        dispatch({
            type: "CLEAR_USER"
        })
    }
}

export const addCompletedQUestion = questionID => {
    return dispatch => {
        dispatch({
            type: "COMPLETED_QUESTION",
            data: {
                questionID
            }
        })
    }
}

export const toggleCompletedQuestion = questionID => {
    return dispatch => {
        dispatch({
            type: "TOGGLE_COMPLETED_QUESTION",
            data: {
                questionID
            }
        })
    }
}

export default userReducer