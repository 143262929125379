import { gql } from "@apollo/client";

// Fragments definitions
const TOPIC_OVERVIEW_DETAILS = gql`
    fragment TopicOverviewDetails on TopicOverview {
        id,
        title,
        igcse,
        visible
    }
`

const BRANCH_DETAILS = gql`
    fragment BranchDetails on Branch {
        id,
        title,
        visible,
        topics {
            ...TopicOverviewDetails
        }
    }
    ${TOPIC_OVERVIEW_DETAILS}
`

const FILE_DETAILS = gql`
    fragment FileDetails on File {
        fileName,
        fileURL
    }
`

const QUESTION_DETAILS = gql`
    fragment QuestionDetails on Question {
        id,
        title,
        answer,
        video,
        text,
        exam_question,
        members_only,
        free_sample,
        level,
        calculator,
        visible,
        attachments {
            ...FileDetails
        }
        reports {
            id
            content
            status
            user{
              firstName
              lastName
              email
            }
            createdAt
        }
    }
    ${FILE_DETAILS}
`

const USER_DETAILS = gql`
    fragment UserDetails on User {
        id,
        type,
        firstName,
        lastName,
        email,
        completedQuestions,
        schoolYear,
        notes
    }
`

const CONFIG_DETAILS = gql`
    fragment ConfigDetails on Config {
        id,
        primaryColor,
        stripeMonthlyPriceID,
        stripeMonthlyTrial,
        stripeYearlyPriceID,
        stripeYearlyTrial,
        dropboxAppKey,
        googleAnalyticsTrackingID
    }
`

const SUBTOPIC_DETAILS = gql`
    fragment SubTopicDetails on SubTopic {
        id,
        topicID,
        title,
        questions {
            ...QuestionDetails
        },
        visible
    }
    ${QUESTION_DETAILS}
`
// END Fragments definitions

export const ALL_BRANCHES = gql`
    query {
        allBranches {
            ...BranchDetails
        }
    }
    ${BRANCH_DETAILS}
`

export const GET_TOPICS = gql`
    query getTopics($branchID: ID!) {
        getTopics(branchID: $branchID) {
            ...TopicOverviewDetails
        }
    }
    ${TOPIC_OVERVIEW_DETAILS}
`

export const GET_QUESTIONS = gql`
    query getQuestions($topicID: ID!) {
        getQuestions(topicID: $topicID) {
            ...QuestionDetails
        }
    }
    ${QUESTION_DETAILS}
`

export const GET_QUESTION = gql`
    query getQuestion($questionID: ID!) {
        getQuestion(questionID: $questionID) {
            ...QuestionDetails
        }
    }
    ${QUESTION_DETAILS}
`

export const GET_USER_DETAILS = gql`
    query {
        getUserDetails {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const ADD_USER = gql`
    mutation addUser(
        $firstName:String!,
        $lastName:String!,
        $email:String!,
        $password:String!,
        $schoolYear:String!
    ) {
        addUser(
            firstName:$firstName,
            lastName:$lastName,
            email:$email,
            password:$password,
            schoolYear:$schoolYear,
        ) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const ADD_MEMBER = gql`
    mutation addMember(
        $user: UserInput!
    ) {
        addMember(
            user: $user
        ) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const LOGIN = gql`
    mutation login(
        $email: String!,
        $password: String!
    ) {
        login(
            email: $email,
            password: $password
        ) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

// Logout query will always return null
export const LOGOUT = gql`
    mutation {
        logout {
            type
        }
    }
`

export const EDIT_BRANCH = gql`
    mutation editBranch(
        $branchID: ID!,
        $newTitle: String!,
        $newVisible: Boolean!
    ) {
        editBranch(
            branchID: $branchID,
            newTitle: $newTitle,
            newVisible: $newVisible,
        ) {
            id,
            title,
            visible
        }
    }
`

export const EDIT_TOPIC = gql`
    mutation editTopic(
        $topicID: ID!,
        $newTitle: String!,
        $newIGCSE: Boolean!,
        $newVisible: Boolean!
    ) {
        editTopic(
            topicID: $topicID,
            newTitle: $newTitle,
            newIGCSE: $newIGCSE,
            newVisible: $newVisible
        ) {
            id,
            title,
            visible
        }
    }
`

export const EDIT_QUESTION = gql`
    mutation editQuestion(
        $questionID: ID!,
        $newTitle: String,
        $newAnswer: String,
        $newVideo: String,
        $newText: String,
        $newExamQuestion: Boolean,
        $newMembersOnly: Boolean,
        $newFreeSample: Boolean,
        $newLevel: String,
        $newCalculator: Boolean,
        $newVisible: Boolean!,
        $newAttachments: [FileInput!]
    ) {
        editQuestion(
            questionID: $questionID,
            title: $newTitle,
            answer: $newAnswer,
            video: $newVideo,
            text: $newText,
            exam_question: $newExamQuestion,
            members_only: $newMembersOnly,
            free_sample: $newFreeSample,
            level: $newLevel,
            calculator: $newCalculator
            visible: $newVisible,
            attachments: $newAttachments
        ) {
            ...QuestionDetails
        }
    }
    ${QUESTION_DETAILS}
    
`

export const ADD_COMPLETED_QUESTION = gql`
    mutation addCompletedQuestion(
        $userID: ID!,
        $questionID: ID!
    ) {
        addCompletedQuestion(
            userID: $userID,
            questionID: $questionID
        ) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const TOGGLE_COMPLETED_QUESTION = gql`
    mutation toggleCompletedQuestion(
        $userID: ID!,
        $questionID: ID!
    ) {
        toggleCompletedQuestion(
            userID: $userID,
            questionID: $questionID
        ) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const GET_ALL_USERS = gql`
    query getAllUsersQuery {
        getAllUsers {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const GET_USER_BY_ID = gql`
    query getUser($userID: ID!) {
        getUser(userID: $userID) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const EDIT_USER = gql`
    mutation editUser(
        $userID: ID!,
        $newType: UserType,
        $newFirstName: String,
        $newLastName: String,
        $newEmail: String,
        $newNotes: String
    ) {
        editUser(
            userID: $userID,
            type: $newType,
            firstName: $newFirstName,
            lastName: $newLastName,
            email: $newEmail,
            notes: $newNotes
        ) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const DELETE_USER = gql`
    mutation deleteUser($userID: ID!) {
        deleteUser(userID: $userID) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const ADD_BRANCH = gql`
    mutation addBranch($title: String!) {
        addBranch(title: $title) {
            ...BranchDetails
        }
    }
    ${BRANCH_DETAILS}
`

export const DELETE_BRANCH = gql`
    mutation deleteBranch($branchID: ID!) {
        deleteBranch(branchID: $branchID) {
            id,
            title
        }
    }
`

export const ADD_TOPIC = gql`
    mutation addTopic(
        $branchID: ID!,
        $title: String!,
        $igcse: Boolean!
    ) {
        addTopic(
            branchID: $branchID,
            title: $title,
            igcse: $igcse
        ) {
            id,
            title,
            igcse,
            questions {
                ...QuestionDetails
            }
        }
    }
    ${QUESTION_DETAILS}
`

export const GET_BRANCH_BY_ID = gql`
    query getBranch($branchID: ID!) {
        getBranch(branchID: $branchID) {
            ...BranchDetails
        }
    }
    ${BRANCH_DETAILS}
`

export const DELETE_TOPIC = gql`
    mutation deleteTopic(
        $branchID: ID!,
        $topicID: ID!
    ) {
        deleteTopic(
            branchID: $branchID,
            topicID: $topicID
        ) {
            id,
            title
        }
    }
`
export const GET_TOPIC_BY_ID = gql`
    query getTopic($topicID: ID!) {
        getTopic(topicID: $topicID) {
            id,
            title
        }
    }
`

export const ADD_QUESTION = gql`
    mutation addQuestion(
        $topicID: ID!,
        $title: String!,
        $answer: String,
        $video: String,
        $text: String,
        $exam_question: Boolean!,
        $members_only: Boolean!,
        $free_sample: Boolean!,
        $level: String!,
        $calculator: Boolean!,
        $attachments: [FileInput!]
    ) {
        addQuestion (
            topicID: $topicID,
            title: $title,
            answer: $answer,
            video: $video,
            text: $text,
            exam_question: $exam_question,
            members_only: $members_only,
            free_sample: $free_sample,
            level: $level,
            calculator: $calculator,
            attachments: $attachments
        ) {
            ...QuestionDetails
        }
    }
    ${QUESTION_DETAILS}
    
`

export const DELETE_QUESTION = gql`
    mutation deleteQuestion(
        $topicID: ID,
        $subTopicID: ID,
        $questionID: ID
    !) {
        deleteQuestion(
            topicID: $topicID,
            subTopicID: $subTopicID,
            questionID: $questionID
        ) {
            ...QuestionDetails
        }
    }
    ${QUESTION_DETAILS}
`

export const GET_PAGE = gql`
    query getPage($name: String!) {
        getPage(name: $name) {
            id,
            name,
            sections {
                id,
                layout,
                yamlContent
            }
        }
    }
`

export const EDIT_SECTION = gql`
    mutation editSection(
        $sectionID: ID!,
        $newYamlContent: String!
    ) {
        editSection(
            sectionID: $sectionID,
            yamlContent: $newYamlContent
        ) {
            id,
            layout,
            yamlContent
        }
    }
`

export const EDIT_PASSWORD = gql`
    mutation editPassowrd(
        $currentPassword: String!,
        $newPassword: String!
    ) {
        editPassowrd(
            currentPassword: $currentPassword,
            newPassword: $newPassword
        ) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const FORGOT_PASSWORD = gql`
    mutation forgotPassword($email: String!) {
        forgotPassword(email: $email)
    }
`

export const CHECK_RESET_TOKEN = gql`
    query checkResetToken($token: String!) {
        checkResetToken(token: $token)
    }
`

export const RESET_PASSWORD = gql`
    mutation resetPassword(
        $token: String!,
        $newPassword: String!
    ) {
        resetPassword(
            token: $token,
            newPassword: $newPassword
        ) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`

export const GET_CONFIG = gql`
    query {
        getConfig {
            ...ConfigDetails
        }
    }
    ${CONFIG_DETAILS}
`

export const EDIT_CONFIG = gql`
    mutation editConfig(
        $primaryColor: String!,
        $stripeMonthlyPriceID: String!,
        $stripeMonthlyTrial: Int!,
        $stripeYearlyPriceID: String!,
        $stripeYearlyTrial: Int!,
        $dropboxAppKey: String,
        $googleAnalyticsTrackingID: String
    ) {
        editConfig(
            primaryColor: $primaryColor
            stripeMonthlyPriceID: $stripeMonthlyPriceID
            stripeMonthlyTrial: $stripeMonthlyTrial
            stripeYearlyPriceID: $stripeYearlyPriceID
            stripeYearlyTrial: $stripeYearlyTrial
            dropboxAppKey: $dropboxAppKey
            googleAnalyticsTrackingID: $googleAnalyticsTrackingID
        ) {
            ...ConfigDetails
        }
    }
    ${CONFIG_DETAILS}
`

export const SORT_QUESTIONS = gql`
    mutation sortQuestions(
        $topicID: ID!,
        $questionIDs: [ID!]!
    ) {
        sortQuestions(
            topicID: $topicID,
            questionIDs: $questionIDs
        ) {
            id,
            title,
            questions {
                ...QuestionDetails
            }
        }
    }
    ${QUESTION_DETAILS}
`

export const SORT_TOPICS = gql`
    mutation sortTopics(
        $branchID: ID!,
        $topicIDs: [ID!]!
    ) {
        sortTopics(
            branchID: $branchID,
            topicIDs: $topicIDs
        ) {
            ...BranchDetails
        }
    }
    ${BRANCH_DETAILS}
`

export const SORT_BRANCHES = gql`
    mutation sortBranches($branchIDs: [ID!]!) {
        sortBranches(branchIDs: $branchIDs) {
            ...BranchDetails
        }
    }
    ${BRANCH_DETAILS}
`

export const GET_SUBTOPICS = gql`
    query getSubTopics($topicID: ID!) {
        getSubTopics(topicID: $topicID) {
            ...SubTopicDetails
        }
    }
    ${SUBTOPIC_DETAILS}
`

export const ADD_SUBTOPIC = gql`
    mutation addSubTopic(
        $topicID: ID!,
        $title: String!
    ) {
        addSubTopic(
            topicID: $topicID,
            title: $title
        ) {
            ...SubTopicDetails
        }
    }
    ${SUBTOPIC_DETAILS}
`

export const EDIT_SUBTOPIC = gql`
    mutation editSubTopic(
        $subTopicID: ID!,
        $newTitle: String!,
        $newVisible: Boolean!
    ) {
        editSubTopic(
            subTopicID: $subTopicID,
            newTitle: $newTitle,
            newVisible: $newVisible
        ) {
            ...SubTopicDetails
        }
    }
    ${SUBTOPIC_DETAILS}
`

export const ADD_QUESTION_TO_SUBTOPIC = gql`
    mutation addQuestionToSubTopic(
        $subTopicID: ID!,
        $topicID: ID,
        $prevSubTopicID: ID,
        $questionID: ID!
    ) {
        addQuestionToSubTopic(
            subTopicID: $subTopicID,
            topicID: $topicID,
            prevSubTopicID: $prevSubTopicID,
            questionID: $questionID
        ) {
            ...SubTopicDetails
        }
    }
    ${SUBTOPIC_DETAILS}
`

export const DELETE_QUESTION_FROM_SUBTOPIC = gql`
    mutation deleteQuestionFromSubTopic(
        $subTopicID: ID!,
        $questionID: ID!,
        $deletePermanently: Boolean,
        $topicID: ID!
    ) {
        deleteQuestionFromSubTopic(
            subTopicID: $subTopicID,
            questionID: $questionID,
            deletePermanently: $deletePermanently,
            topicID: $topicID
        ) {
            ...SubTopicDetails
        }
    }
    ${SUBTOPIC_DETAILS}
`

export const DELETE_SUBTOPIC = gql`
    mutation deleteSubTopic(
        $subTopicID: ID!,
        $topicID: ID!,
        $deleteQuestionsPermanently: Boolean
    ) {
        deleteSubTopic(
            subTopicID: $subTopicID,
            topicID: $topicID,
            deleteQuestionsPermanently: $deleteQuestionsPermanently
        ) {
            ...SubTopicDetails
        }
    }
    ${SUBTOPIC_DETAILS}
`

export const SORT_SUBTOPICS = gql`
    mutation sortSubTopics($subTopicIDs: [ID!]!) {
        sortSubTopics(subTopicIDs: $subTopicIDs) {
            ...SubTopicDetails
        }
    }
    ${SUBTOPIC_DETAILS}
`

export const SORT_SUBTOPIC_QUESTIONS = gql`
    mutation sortSubTopicQuestions(
        $subTopicID: ID!,
        $questionIDs: [ID!]!
    ) {
        sortSubTopicQuestions(
            subTopicID: $subTopicID,
            questionIDs: $questionIDs
        ) {
            ...SubTopicDetails
        }
    }
    ${SUBTOPIC_DETAILS}
`

export const GET_STRIPE_CHECKOUT_ID = gql`
    query getStripeCheckoutID(
        $type: SubscriptionType!
    ) {
        getStripeCheckoutID(
            type: $type
        )
    }
`

export const GET_STRIPE_PORTAL_URL = gql`
    query {
        getStripePortalURL
    }
`

export const EXPORT_USERS = gql`
    query {
        exportUsers
    }
`

export const GET_TOPICS_COUNT = gql`
    query getTopicsCount($branchID: ID!) {
        getTopicsCount(branchID: $branchID)
    }
`

export const GET_QUESTIONS_COUNT = gql`
    query getQuestionsCount($topicID: ID!) {
        getQuestionsCount(topicID: $topicID)
    }
`

export const GET_BRANCH_STATS = gql`
    query getBranchStats($branchID: ID!) {
        getBranchStats(branchID: $branchID)
    }
`
// 
export const ALL_QUESTION_SLIDER = gql`
query {
    allQuestionSlider{
        id
        title
        subText
        equation
        bgImage
    }
}
`

export const ADD_QUESTION_SLIDE = gql`
mutation addSlide ($title: String!, $equation: String!, $subText: String!, $bgImage: String!) {
    addQuestionSlider(title: $title, equation: $equation, subText: $subText, bgImage: $bgImage) {
        id
        title
        subText
        equation
        bgImage
    }
  }
`

export const DELETE_QUESTION_SLIDE = gql`
mutation deleteQuestionSlider($QuestionSliderID: ID!) {
    deleteQuestionSlider(QuestionSliderID: $QuestionSliderID) {
        id,
        title
    }
}
`
export const EDIT_QUESTION_SLIDE = gql`
mutation editQuestionSlider( $QuestionSliderID: ID!, $newTitle: String!, $newEquation: String!, $newSubText: String!, $newBgImage: String!) {
    editQuestionSlider(
        QuestionSliderID: $QuestionSliderID, 
        newTitle: $newTitle,
        newEquation: $newEquation,
        newSubText: $newSubText,
        newBgImage: $newBgImage
        ) {
        id,
        title
    }
}
`

// 
export const ALL_TESTIMONIAL = gql`
query {
    allTestimonial{
        id
        feedback
        author
        bgImage
    }
}
`
export const ADD_TESTIMONIAL = gql`
mutation addTestimonial ($feedback: String!, $author: String!, $bgImage: String!) {
    addTestimonial(feedback: $feedback, author: $author, bgImage: $bgImage) {
        id
        feedback
        author
        bgImage
    }
  }
`
export const DELETE_TESTIMONIAL = gql`
mutation deleteTestimonial($TestimonialID: ID!) {
    deleteTestimonial(TestimonialID: $TestimonialID) {
        id
    }
}
`
export const EDIT_TESTIMONIAL = gql`
mutation editTestimonial( $TestimonialID: ID!, $newFeedback: String! ) {
    editTestimonial(
        TestimonialID: $TestimonialID, 
        newFeedback: $newFeedback,
        ) {
        id
        feedback
    }
}
`

export const LOGIN_WITH_GOOGLE = gql`
${USER_DETAILS}
    mutation loginWithGoogle(
    $access_token: String!
){
    loginWithGoogle(access_token: $access_token){
        ...UserDetails
    }
    
}
`

// QUESTION REPORT

export const ADD_QUESTION_REPORT = gql`
    mutation addQuestionReport(
        $questionID: ID!,
        $content: String!
    ) {
        reportQuestion(
            questionID: $questionID, 
            content: $content
        ){
            content
            status
        }
    }
`

export const TOGGLE_QUESTION_REPORT_STATUS = gql`
    mutation toggleQuestionReportStatus(
        $reportID: ID!
    ) {
        toggleReportStatus(
            reportID: $reportID
        ){
            content
            status
        }
    }
`

export const DELETE_QUESTION_REPORT = gql`
    mutation deleteQuestionReport(
        $reportID: ID!
    ) {
        deleteReport(
            reportID: $reportID
        ){
            content
            status
        }
    }
`