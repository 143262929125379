import FormInput from "./FormInput"
import Button from "./Button"
import styled from "styled-components/macro"

const Input = styled(FormInput)``

const Form = styled.form`

    &.compact {
        max-width: 25rem;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    &.notFirstMargin {
        ${Input}:first-of-type {
            margin-top: 0;
        }
    }
    
    &.centerText {
        text-align: center;
    }

    ${Input}, ${Button}, .rc-md-editor { /* rc-md-editor is the yaml editor */
        margin-top: 2rem;
    }

    select {
        padding: 1rem;
    }

    span.label, input[type="radio"] { /* Use span.label to not interfere with katex */
        margin-right: 1rem;
    }

    input[type="radio"] {
        margin-left: .25rem;
    }
`

export { Form, Input }