import React from 'react';
import { Link } from "react-router-dom";
import logo from "../../../static/new-logo-nobg.svg"

const Footer = () => {
  return (
    <footer>
      <div className="py-[40px] md:py-[56px] container bg-[#4463F2] min-w-full">
        <div
          className=' after:text-white after:text-[35px]
            after:font-bold after:absolute after:mt-[-40px] after:ml-[32px]'>
          <img src={logo} width={200} alt="logo" />
        </div>
        <a
          href="mailto:contact@mathsadvance.co.uk"
          target='_blank'
          rel="noopener noreferrer"
          className="block text-[16px] sm:text-[20px] md:text-[16px] md:hidden text-white my-[40px]">
          contact@mathsadvance.co.uk
        </a>
        <div className="flex flex-col md:inline-flex lg:mt-[10px]">
          <h2 className="mt-[40px] hidden mb-[20px]  text-[16px] sm:text-[20px] md:text-[16px] font-bold text-white md:inline-block">
            Pages
          </h2>
          <nav
            id="footer-page-navigation"
            role="navigation"
            aria-label="Footer Page Navigation"
            className="flex flex-col md:inline-flex">
            <Link
              className="text-white text-[16px] sm:text-[20px] md:text-[16px] hover:text-[#ccc] transition-all duration-300"
              to="/about">
              About
            </Link>
            <Link
              className="text-white text-[16px] sm:text-[20px] md:text-[16px] hover:text-[#ccc] transition-all duration-300"
              to="/questions">
              Questions
            </Link>
            <Link
              className="text-white text-[16px] sm:text-[20px] md:text-[16px] hover:text-[#ccc] transition-all duration-300"
              to="/subscribe">
              Subcribe
            </Link>
            <Link
              className="text-white text-[16px] sm:text-[20px] md:text-[16px] hover:text-[#ccc] transition-all duration-300"
              to="/terms-and-conditions">
              Terms and Conditions
            </Link>
            <Link
              className="text-white text-[16px] sm:text-[20px] md:text-[16px] hover:text-[#ccc] transition-all duration-300"
              to="/privacy-policy">
              Privacy Policy
            </Link>
          </nav>
        </div>

        <div className="flex flex-col md:inline-flex md:ml-[120px] lg:ml-[250px] ">
          <h2 className="mt-[40px] mb-[20px] text-[16px] sm:text-[20px] md:text-[16px] font-bold text-white md:inline-block">
            Follow Us
          </h2>
          <nav
            id="footer-social-navigation"
            role="navigation"
            aria-label="Footer Social Navigation"
            className="flex flex-col md:inline-flex ">
            <a
              href="https://mathsadvance.blog/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-[16px] sm:text-[20px] md:text-[16px] hover:text-[#ccc] transition-all duration-300"
            >
              Blog
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-[16px] sm:text-[20px] md:text-[16px] hover:text-[#ccc] transition-all duration-300"
              href="https://twitter.com/maths_advance">
              Twitter/X
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-[16px] sm:text-[20px] md:text-[16px] hover:text-[#ccc] transition-all duration-300"
              href="https://uk.linkedin.com/company/maths-advance">
              Linkedin
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-[16px] sm:text-[20px] md:text-[16px] hover:text-[#ccc] transition-all duration-300"
              href="https://www.instagram.com/mathsadvance/">
              Instagram
            </a>
          </nav>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
