import React, { useState } from "react"
import styled from "styled-components/macro"

const StyledLabel = styled.label`
    position: absolute;
    top: 0.6rem;
    left: 0.5rem;
    color: #777;
    transition: all 250ms ease;
`

const InputContainer = styled.div`
    position: relative;
    border-bottom: .25rem solid #333;
    height: 3rem;
    input, textarea {
        display: block;
        border: none;
        width: 100%;
        height: 100%;
        padding: .5rem;
        font-size: 1rem;
        outline: none;
        position: relative;
        background: none;
        z-index: 1;
    }
    input[type="checkbox"]{
        margin-left: 6px;
        width:25px;
        height:100%;
    }
    &.textarea, textarea {
        height: 15rem;
        resize: none;
    }

    /* Use pseudo after to create border animation */
    &:after {
        content: "";
        width: 100%;
        height: .25rem;
        background: ${({ theme }) => theme.primary};
        position: absolute;
        bottom: -.25rem;
        left: 0;
        transform: scaleX(0);
        transform-origin: 0;
        transition: transform 250ms linear;
    }

    &.focus:after {
        transform: scaleX(1);
    }

    &.filled {
        ${StyledLabel} {
            transform: translateY(-1.5rem);
            font-size: 0.75rem;
            color: #333;
        }
    }

    
    @media only screen and (min-width: 990px) {
        &.textarea, textarea {
            height: 100%;
        }
    }
`

InputContainer.defaultProps = {
    theme: {
        primary: "#4463f2"
    }
}

const FormInput = ({ className, type = "text", name, label, required = false, initialValue = "", disabled = false, onChange = () => { }, textAreaProps }) => {
    const [value, setValue] = useState(initialValue)
    const [focus, setFocus] = useState(false)

    const props = {
        required,
        type,
        name,
        value,
        disabled,
        onChange: e => { setValue(e.target.value); onChange(e.target.value) },
        onFocus: () => setFocus(!focus),
        onBlur: () => setFocus(!focus)
    }

    if (type === "checkbox") {
        props.checked = value
        props.onChange = () => setValue(!value)
    }

    return <InputContainer className={className + ` ${type} ` + (value === "" ? "" : " filled") + (focus ? " focus" : "")}>
        {type !== "textarea" && <input {...props} />}
        {type === "textarea" && <textarea
            {...props}
            {...textAreaProps}
        />}
        <StyledLabel htmlFor={name}>{label}</StyledLabel>
    </InputContainer>
}

export default FormInput